<template>
  <div class="user-email-address-container mb-5">
    <v-form v-model="isValid" ref="form" @submit.prevent>
      <v-row>
        <!-- <v-col cols="12"
          ><div class="d-flex img-wraper">
            <img
              alt="CardExample"
              width="450"
              src="/img_layout/cardExample.png"
            /></div
        ></v-col> -->
        <!--Card-->
        <v-col cols="12" md="6">
          <label v-html="`${$t('profile.cardNumber')} *`" />
          <v-text-field
            class="mt-1 rounded-pill"
            v-model="userData.fidelityCard"
            :rules="[requiredRules()]"
            :placeholder="`${$t('profile.cardNumberPlaceholder')}`"
            required
            outlined
            dense
            hide-details="auto"
            :disabled="isFieldDisabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <!-- Fiscal Code -->
          <label v-html="`${$t('profile.fiscalCode')}` + '*'" />
          <v-text-field
            class="mt-1 rounded-pill"
            v-model="userData.person.fiscalCode"
            :rules="fiscalCodeRules"
            :placeholder="`${$t('profile.fiscalCode')}`"
            hide-details="auto"
            outlined
            dense
          />
        </v-col>
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.contacts.mobilePhone')} *`" />
          <v-text-field
            class="mt-1 rounded-pill"
            v-model="userData.phone"
            :rules="phoneRules"
            :placeholder="`${$t('profile.contacts.mobilePhone')}`"
            outlined
            dense
            :disabled="isFieldDisabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <!-- email -->
          <label v-html="`${$t('profile.contacts.email')}*`" />
          <v-text-field
            v-model="userData.email"
            :placeholder="`${$t('profile.contacts.email')}*`"
            :rules="emailRules"
            required
            outlined
            dense
            class="mt-1 rounded-pill"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <!-- confirm email -->
          <label v-html="`${$t('profile.email.emailConfirm')}*`" />
          <v-text-field
            v-model="userData.emailConfirm"
            :placeholder="`${$t('profile.email.emailConfirm')}`"
            :rules="emailConfirmRules"
            outlined
            dense
            @paste.prevent
            class="mt-1 rounded-pill"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <!-- password -->
          <label v-html="`${$t('profile.password')}*`" />
          <v-text-field
            v-model="userData.password"
            :placeholder="`${$t('profile.password')}*`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
            class="mt-1 rounded-pill"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <!-- conferma password -->
          <label v-html="`${$t('profile.passwordConfirm')}*`" />
          <v-text-field
            :placeholder="`${$t('profile.passwordConfirm')}*`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirmRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
            @paste.prevent
            class="mt-1 rounded-pill"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import {
  requiredValue,
  isMobilePhone,
  isEmail,
  minLength,
  minSymbolCharacter,
  minCapitalCharacter
} from "~/validator/validationRules";
import { isFiscalCode } from "@/customValidationRules";

export default {
  name: "UserEmailAddress",
  props: {
    isFieldDisabled: { type: Boolean, default: false },
    userData: { type: Object, required: false },
    isProfileForm: { type: Boolean, default: false },
    showButtons: { type: Boolean, default: true },
    hasCard: { type: Boolean, default: false },
    existingFidelity: { type: String, required: false }
  },
  components: {},
  data() {
    return {
      isValid: false,
      showPassword: false,
      passwordConfirm: null,
      menu: false,
      response: {},
      dateRules: [requiredValue("Digitare la data di nascita")],
      requiredRules: requiredValue,
      fiscalCodeRules: [requiredValue(), isFiscalCode()],
      phoneRules: [requiredValue(), isMobilePhone()],
      emailRules: [requiredValue(), isEmail()],
      emailConfirmRules: [
        v => !!v || "Confermare l'e-mail",
        v => v === this.userData.email || "L'e-mail non coincidono"
      ],
      passwordRules: [
        requiredValue("Digitare la password"),
        minLength(8, "La password deve essere lunga almeno 8 caratteri"),
        minCapitalCharacter(),
        minSymbolCharacter()
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.userData.password || "Le password non coincidono"
      ],
      pickerDate: this.$dayjs()
        .subtract(25, "years")
        .format("YYYY-MM-DD")
    };
  },
  computed: {
    disabledRegistration() {
      return !this.isValid;
    },
    birthDate: {
      get: function() {
        if (this.userData.person.birthDate) {
          return this.$dayjs(this.userData.person.birthDate).format(
            "DD/MM/YYYY"
          );
        } else {
          return null;
        }
      },
      set: function(value) {
        this.userData.person.birthDate = this.$dayjs(
          value,
          "DD/MM/YYYY"
        ).format("DD/MM/YYYY");
      }
    },
    maxBirthDate() {
      return this.$dayjs()
        .subtract(18, "years")
        .format("YYYY-MM-DD");
    }
  },
  methods: {
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    back() {
      this.$emit("back");
    },
    submit() {
      this.$emit("submit", true, true);
    },
    onBirthdateInput() {
      this.menu = false;
    }
  },

  mounted() {
    if (this.existingFidelity) {
      this.userData.fidelityCard = this.existingFidelity;
    }
  },
  watch: {
    existingFidelity: function(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.userData.fidelityCard = this.existingFidelity;
      }
    }
  }
};
</script>
