<template>
  <v-container class="registration-user-container px-3">
    <!-- <CategoryTitle
      :category="category"
      class="mt-0 my-lg-6"
      :add-container="false"
    /> -->
    <div class="category-title container--fluid px-0 mt-0 my-lg-6">
      <div class="d-flex align-center flex-row flex-wrap">
        <h1>{{ category?.category_info?.TITLE || category.name }}</h1>
        <div
          v-if="selfScanning"
          class="d-flex align-center justify-end flags-container ml-auto"
        >
          <img src="/img_layout/flags/it.png" @click="setLanguage('it')" />
          <img src="/img_layout/flags/de.png" @click="setLanguage('de')" />
        </div>
      </div>
    </div>

    <div>
      <label>{{ $t("register.user.alreadyCustomer") }}?*</label>
      <v-radio-group v-model="hasCard" row @change="resetUserData">
        <v-radio
          color="default"
          :label="$t('common.yes')"
          :value="true"
        ></v-radio>
        <v-radio
          color="default"
          :label="$t('common.no')"
          :value="false"
        ></v-radio>
      </v-radio-group>
      <!-- <v-dialog v-model="dialog" max-width="460">
        <v-card @click="clicked">
          <div class="d-flex align-start justify-center">
            <v-card-text class="pt-0 pr-0">
              <p
                class="content"
                v-html="$t('register.registrationNoCardConfirmPopup.content')"
              ></p>
            </v-card-text>
            <v-btn
              depressed
              plain
              class="d-flex align-start"
              icon
              @click.stop="dialog = false"
            >
              <v-icon>$close</v-icon>
            </v-btn>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" outlined depressed @click="dialog = false">
              {{ $t("common.no") }}
            </v-btn>

            <v-btn color="primary" depressed to="/page/diventa-socio-coop">
              {{ $t("common.yes") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </div>
    <div v-if="hasCard">
      <div>
        <UserEmailAddress
          ref="userEmailAddressDataForm"
          :userData.sync="userData"
          :hasCard="hasCard"
          :isFieldDisabled="step == 2"
          :existingFidelity="existingFidelity"
        />
      </div>
    </div>
    <div v-if="!hasCard || step == 2">
      <h3 v-html="$t('register.user.title')"></h3>
      <span class="description" v-html="$t('register.user.description')" />
      <NewCustomer
        ref="newCustomerDataForm"
        :userData.sync="userData"
        :hasCard="hasCard"
        :isFieldDisabled="hasCard == null"
      />
      <v-row>
        <v-col cols="12"
          ><div v-if="userData.companyId">
            <PrivacyOptions
              ref="privacyOptionsForm"
              :storeId="userData.companyId"
              :userData="userData"
              :showUpdateButton="false"
            /></div
        ></v-col>
      </v-row>
    </div>
    <v-form ref="privacyForm" v-model="isValid">
      <v-row no-gutters>
        <v-col cols="12">
          <div
            class="privacy-conditions-wrapper general-conditions disclaimer-text grey lighten-3 my-4 pa-2 rounded-lg"
          >
            <iframe
              class="w-100"
              height="300"
              src="/general-conditions.htm"
              frameborder="0"
              scrolling="yes"
            ></iframe>
          </div>
        </v-col>
        <v-col cols="12">
          <v-checkbox
            :rules="checkboxRules"
            v-model="consents.general"
            class="disclaimer-checkbox"
            color="primary"
            false-value="false"
            true-value="true"
            required
            @change="updateConsents"
          >
            <template v-slot:label>
              <span
                class="disclaimer-label disclaimer-checkbox-label"
                v-html="$t('register.privacy.requiredCheckGeneral')"
              ></span>
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12">
          <div
            class="privacy-conditions-wrapper general-conditions disclaimer-text grey lighten-3 my-4 pa-2 rounded-lg"
          >
            <iframe
              class="w-100"
              height="300"
              src="/privacy-terms.htm"
              frameborder="0"
              scrolling="yes"
            ></iframe>
          </div>
        </v-col>

        <v-col cols="12">
          <v-checkbox
            :rules="checkboxRules"
            v-model="consents.privacy"
            class="disclaimer-checkbox"
            color="primary"
            false-value="false"
            true-value="true"
            required
            @change="updateConsents"
          >
            <template v-slot:label>
              <span
                class="disclaimer-label disclaimer-checkbox-label"
                v-html="$t('register.privacy.requiredCheckPrivacy')"
              ></span>
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="12">
          <v-checkbox
            v-model="consents.marketing"
            class="disclaimer-checkbox"
            color="primary"
            false-value="false"
            true-value="true"
            @change="updateConsents"
          >
            <template v-slot:label>
              <span
                class="disclaimer-label disclaimer-checkbox-label"
                v-html="$t('register.privacy.consents.marketing')"
              ></span>
            </template>
          </v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-checkbox
            v-model="consents.profilation"
            class="disclaimer-checkbox"
            color="primary"
            false-value="false"
            true-value="true"
            @change="updateConsents"
          >
            <template v-slot:label>
              <span
                class="disclaimer-label disclaimer-checkbox-label"
                v-html="$t('register.privacy.consents.profilation')"
              ></span>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-form>

    <div>* {{ $t("profile.requiredFields") }}</div>
    <div class="d-flex justify-end">
      <!--
      <v-btn
        v-if="hasCard && step != 2"
        @click="checkCard"
        depressed
        large
        color="primary"
        >{{ $t("register.user.verify") }}</v-btn
      >
    -->
      <v-btn
        @click="register"
        depressed
        large
        color="primary"
        class="rounded-pill px-6"
        >{{ $t("register.user.signIn") }}</v-btn
      >
    </div>
    <v-dialog v-model="invalidForm" max-width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Campi mancanti o invalidi
        </v-card-title>

        <v-card-text class="mt-6">
          Controlla il formulario, esistono campi invalidi o campi obbligatorii
          non compilati.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="invalidForm = false">
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ResponseMessage :response="response" class="mt-4" />
  </v-container>
</template>

<style lang="scss">
iframe {
  overflow-y: scroll;
}
.v-text-field--outlined fieldset {
  color: #d5d5d5 !important;
}
.flags-container {
  gap: 20px;
  img {
    cursor: pointer;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    border: 1px solid #000000;
  }
}
.disclaimer-text {
  font-size: 16px;
  font-weight: 400;
  color: #707070;
}

.content {
  font-size: 1rem;
}
.registration-user-container {
  //color: var(--v-primary-base);
  .v-image__image--cover {
    background-size: inherit;
  }
  .description {
    font-size: 14px;
  }
  .v-card {
    border: 1px solid var(--v-grey-lighten3);
    color: var(--v-primary-base);
    height: 100%;
    .v-card__title {
      font-size: 30px;
      word-break: break-word;
      line-height: 42px;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    h1 {
      font-size: 25px !important;
      line-height: 3rem;
    }
    .description {
      font-size: 12px !important;
    }
    .v-card {
      height: auto;
    }
  }
  label {
    font-weight: 600;
    text-transform: uppercase;
  }
  .v-select .v-select__selection--comma {
    color: inherit !important;
    font-weight: normal !important;
  }
  .disclaimer-checkbox {
    .v-label {
      font-size: 12px !important;
    }
  }
  .img-wraper {
    img {
      max-width: 100%;
      border-radius: 8px;
    }
  }
}
</style>

<script>
// import CategoryTitle from "@/components/category/CategoryTitle.vue";
import UserEmailAddress from "@/components/profile/form/UserEmailAddress";
import NewCustomer from "@/components/profile/form/NewCustomer.vue";
import PrivacyOptions from "@/components/profile/form/PrivacyOptions.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";
//import CustomConfirmDialog from "@/components/CustomConfirmDialog.vue";
import clickHandler from "~/mixins/clickHandler";
import categoryMixins from "~/mixins/category";
import CustomService from "@/service/customService";
import analyticsService from "@/commons/service/analyticsService";
import { requiredValue, isCheckboxTrue } from "~/validator/validationRules";
export default {
  name: "RegistrationUser",
  data() {
    return {
      isValid: false,
      dialog: false,
      response: {},
      isSocialLogin: false,
      hasCard: true,
      step: 1,
      userInformationFields: [],
      userPrivacyFields: [],
      userInfoAndPrivacyFields: [],
      invalidForm: false,
      supportData: {
        privacy1: true,
        privacy2: true,
        bornCountry: {
          name: null
        }
      },
      consents: {
        general: "false",
        privacy: "false",
        marketing: "false",
        profilation: "false"
      },
      checkboxRules: [
        requiredValue("È obbligatorio accettare questo consenso per procedere"),
        isCheckboxTrue("È obbligatorio accettare questo consenso per procedere")
      ],
      userData: {
        person: {
          personInfos: [
            {
              personInfoTypeId: "107",
              value: "false"
            },
            {
              personInfoTypeId: "101",
              value: "false"
            },
            {
              personInfoTypeId: "102",
              value: "false"
            },
            {
              personInfoTypeId: "108",
              value: "false"
            }
          ]
        },
        contact: {}
      },
      existingFidelity: null
    };
  },
  mixins: [categoryMixins, clickHandler],
  components: {
    // CategoryTitle,
    UserEmailAddress,
    NewCustomer,
    ResponseMessage,
    PrivacyOptions
  },
  methods: {
    // async openConfirmPopup() {
    //   let res = await this.$dialog.show(CustomConfirmDialog, {
    //     waitForResult: true,
    //     path: "register.registrationNoCardConfirmPopup",
    //     dialogClass: "registration-no-card-dialog"
    //   });
    //   if (!res) {
    //     this.hasCard = true;
    //   }
    // },
    async setLanguage(lang) {
      if (this.$i18n.locale !== lang) {
        this.$i18n.locale = lang;
        sessionStorage.setItem("X-Ebsn-Lang", lang.toLowerCase());
        const url = new URL(window.location);
        url.searchParams.delete("lang");
        window.history.pushState({}, "", url);

        global.EventBus.$emit("restart");
      }
    },
    setPersonInfo(personInfoTypeId, value) {
      const privacyPersonInfo = {
        personInfoTypeId: personInfoTypeId,
        value: this.consents.privacy
      };

      let existingPersonInfo = this.userData.person.personInfos.find(
        info => info.personInfoTypeId === personInfoTypeId
      );

      if (existingPersonInfo) {
        existingPersonInfo.value = value;
      } else {
        this.userData.person.personInfos.push(privacyPersonInfo);
      }
    },
    updateConsents() {
      this.setPersonInfo("107", this.consents.general);
      this.setPersonInfo("101", this.consents.privacy);
      this.setPersonInfo("102", this.consents.marketing);
      this.setPersonInfo("108", this.consents.profilation);
    },
    resetUserData() {
      this.userData.fidelityCard = null;
      this.userData.person = {
        personInfos: [
          {
            personInfoTypeId: "107",
            value: "false"
          },
          {
            personInfoTypeId: "101",
            value: "false"
          },
          {
            personInfoTypeId: "102",
            value: "false"
          },
          {
            personInfoTypeId: "108",
            value: "false"
          }
        ]
      };
    },
    async register() {
      let _this = this;
      this.response = {};
      let userDataValid;
      if (this.hasCard) {
        userDataValid = this.$refs.userEmailAddressDataForm.$refs.form.validate();
      } else {
        userDataValid = this.$refs.newCustomerDataForm.$refs.form.validate();
      }
      let privacyDataValid = _this.$refs.privacyForm.validate();
      if (userDataValid && privacyDataValid) {
        this.updateConsents();

        // if (!this.hasCard) {
        //   await this.$refs.privacyOptionsForm.applyChages();
        // }
        let _this = this;
        CustomService.registerUser(this.userData).then(data => {
          if (data.response.status == 0) {
            analyticsService.signUp(
              _this.hasCard ? "card" : "nocard",
              data.data.user.userId
            );
            if (window.fbq) window.fbq("track", "CompleteRegistration");

            this.$router.push({ name: "RegistrationCompletedAgreement" });
          } else {
            this.response = data.response;
          }
        });
      } else {
        this.invalidForm = true;
        /**
         * If only user infotmation fields are present
         */
        if (this.$refs.newCustomerDataForm) {
          this.userInformationFields = this.$refs.newCustomerDataForm.$refs.form.$children.filter(
            field => {
              if (field.type === "button") {
                return false;
              }
              if (field.$el && field.$el.className.includes("menu")) {
                return false;
              }
              // eslint-disable-next-line no-prototype-builtins
              if (field.hasOwnProperty("valid")) {
                return !field.valid;
              }
              return true;
            }
          );
          /**
           * Focus on the first invalid field in user information fields list
           */
          this.scrollAndFocusOnFirstInvalidField(this.userInformationFields);
        }
        /**
         * If both user infotmation and privacy fields are present
         */
        if (this.$refs.newCustomerDataForm && this.$refs.privacyOptionsForm) {
          this.userPrivacyFields = this.$refs.privacyOptionsForm.$refs.form.$children.filter(
            field => !field.valid && field.type !== "button"
          );
        }
        /**
         * Focus on the first invalid field in user information and privacy fields list
         */
        if (this.userInformationFields && this.userPrivacyFields) {
          this.userInfoAndPrivacyFields = [
            ...this.userInformationFields,
            ...this.userPrivacyFields
          ];
          this.scrollAndFocusOnFirstInvalidField(this.userInfoAndPrivacyFields);
        }
      }
    },
    scrollAndFocusOnFirstInvalidField(fields) {
      if (fields.length > 0) {
        fields[0].$el.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
        fields[0].$el.focus();
      }
    }
    // checkCard() {
    //   if (this.$refs.userEmailAddressDataForm.$refs.form.validate()) {
    //     CustomService.checkCard(
    //       this.userData.fidelityCard,
    //       this.userData.person.birthDate
    //     ).then(data => {
    //       if (data.response.status == 0) {
    //         this.step = 2;
    //         this.userData = data.data.user;
    //       } else {
    //         this.response = data.response;
    //       }
    //     });
    //   }
    // }
  },
  mounted() {
    if (this.$route.query.fidelitycard) {
      this.existingFidelity = this.$route.query.fidelitycard;
    }
    if (this.$route.query.lang) {
      this.setLanguage(this.$route.query.lang);
    }
  },
  watch: {
    "$route.query.fidelitycard": function(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.existingFidelity = newVal;
        this.hasCard = true;
      }
    },
    "$route.query.lang": function(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.setLanguage(newVal);
      }
    }
  }
};
</script>
